/* Font family */
$font-family-default: 'Arial', sans-serif;
$font-family-heading: 'Arial', sans-serif;

/* Font size */
$font-size-min-base: 16px;
$font-size-max-base: 16px;
$font-size-base: 1rem;
$font-size-10: ($font-size-base * .75);
$font-size-20: ($font-size-base * .875);
$font-size-30: ($font-size-base * 1);
$font-size-40: ($font-size-base * 1.125);
$font-size-50: ($font-size-base * 1.25);
$font-size-60: ($font-size-base * 1.5);
$font-size-default: $font-size-30;

$font-size-h1: ($font-size-base * 3);
$font-size-h2: ($font-size-base * 2);
$font-size-h3: ($font-size-base * 1.25);
$font-size-h4: ($font-size-base * 1.125);
$font-size-h5: ($font-size-base * 1);
$font-size-h6: ($font-size-base * 1);

/* Font weight */
$font-weight-30: 300;
$font-weight-40: 400;
$font-weight-50: 500;
$font-weight-60: 600;
$font-weight-70: 700;
$font-weight-90: 900;
$font-weight-default: $font-weight-40;

/* Line height */
$line-height-10: 1;
$line-height-15: 1.125;
$line-height-20: 1.25;
$line-height-30: 1.5;
$line-height-40: 1.75;
$line-height-default: $line-height-30;

/* Color */

$color-default-base: white;
$color-primary-base: #1432FF;
$color-secondary-base: #1F1E23;
$color-background: #1B2228;
$color-background-alt: #262D32;

$color-success: #0FB923;
$color-danger: #FD0023;
$color-unknown: #4A4A4A;

/* Border radius */
$border-radius: 2px;
$border-color: #3B4044;

/* Space */
$space-base: ($font-size-default * $line-height-default);
$space-10: ($space-base * .25);
$space-15: ($space-base * .375);
$space-20: ($space-base * .5);
$space-25: ($space-base * .75);
$space-30: ($space-base * 1);
$space-40: ($space-base * 1.5);
$space-50: ($space-base * 2);
$space-60: ($space-base * 3);
$space-default: $space-base;

/* Width */
// $width-10: 320px;
$width-20: 560px;
$width-25: 768px;
$width-27: 992px;
$width-30: 1600px;
$width-50: 1920px;
$width-default: $width-50;

/* Gutter */
$gutter-10: $space-10;
$gutter-20: $space-20;
$gutter-30: $space-30;
$gutter-40: $space-40;
$gutter-50: $space-50;
$gutter-60: $space-60;
$gutter-default: $gutter-30;


/* Breakpoints */
$break-min-20: (min-width: $width-20);
$break-min-25: (min-width: $width-25);
$break-min-27: (min-width: $width-27);

$break-max-20: (max-width: $width-20 - 1);
