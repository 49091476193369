@import '~assets/scss/global';

.t-aside {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 20;

  width: 33vw;

  will-change: transform;
  transform: translateX(100%);

  &--visible {
    box-shadow: 0 0 10px 0 $border-color;
    background-color: darken($color-background, 2.5%);
    transition: transform 0.3s;
    transform: translateX(0);
  }
}
