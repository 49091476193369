/* Fieldset */
fieldset {
  border: none;
  margin: $space-20 0 $space-30;
  padding: 0;
}

/* Label and legend */
label,
legend {
  cursor: pointer;
  font-weight: $font-weight-70;
  line-height: $line-height-20;
  margin: 0;
  margin: 0 0 ($space-10 * 1.5);
}

/* Button */
button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: $line-height-default;
  cursor: pointer;
}

/* Form */
input,
textarea,
select {
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: $line-height-20;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;

  &:focus {
    border-color: $color-primary-base;
  }

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: .3;
    pointer-events: none;
  }

  &::placeholder {
    // color: $color-default-30;
    font-style: italic;
  }
}

textarea {
  max-width: none;
  resize: vertical;
}
