@import '~assets/scss/global';

.m-unit-log {
  // padding: .25rem;
  // background-color: white;
  // border: $border-radius solid $border-color;

  &__stats {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__stat--weight {
    @for $i from 0 through 100 {
      &-#{$i} {
        flex: $i;
      }
    }
  }

  &__info {
    background-color: $color-background;
    color: transparentize($color: white, $amount: 0.5);
    padding: 0.5rem;
    border-width: 0 $border-radius $border-radius;
    border-style: solid;
    border-color: $border-color;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__availability-status {
    margin-left: auto;
    font-size: 1.35rem;

    &--available {
      color: $color-success;
    }

    &--unavailable {
      color: $color-danger;
    }
  }

  &__timing {
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 0.925rem;
  }

  &__stat {
    font-weight: bold;
    height: 1.5rem;
    text-align: center;
    line-height: 1.5rem;
    font-size: 0.875rem;
    color: transparentize($color: white, $amount: 0.5);

    &:hover {
      color: white;
    }

    &--padding {
      padding: 0 0.25rem;
    }

    &--type-available {
      background-color: $color-success;
    }

    &--type-unavailable {
      background-color: $color-danger;
    }

    &--type-unknown {
      background-color: $color-unknown;
    }
  }

  &--pending {
    .m-unit-log__availability-status {
      color: transparentize($color: $color-primary-base, $amount: 0.5);
      // background-color: transparentize($color: blue, $amount: .85);
    }
  }

  &--available {
    .m-unit-log__info {
      // box-shadow: 2px 2px 2px red;
      box-shadow: inset 0px -1px 0px $color-success;
      border-bottom-color: $color-success;
      border-bottom-width: 1px;
      // border-color: $color-success;
      // background-color: transparentize($color: $color-success, $amount: .75);
    }
  }

  &--unavailable {
    .m-unit-log__info {
      // background-color: transparentize($color: $color-danger, $amount: .75);
    }
  }
}
