@import '~assets/scss/global';

.m-unit-report {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  align-items: center;

  & + & {
    margin-top: 1rem;

    &:before {
      content: '';
      width: 3px;
      height: 1rem;
      display: block;
      background: $border-color;
      position: absolute;
      top: -1rem;
      bottom: -100%;
      left: 5rem;
    }
  }

  &__data {
    border-radius: $border-radius;
    border: 1px solid $border-color;
    padding: 0.75rem 1rem;
    background: $color-unknown;
    position: relative;
  }

  &__title {
    margin: 0;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }

  &__meta {
    position: absolute;
    right: calc(100% + 1rem);
    text-align: right;
    color: transparentize($color: white, $amount: 0.5);
    font-weight: bold;
    font-size: 0.872rem;

    &--white {
      display: block;
      font-size: 1.125rem;
      color: white;
    }
  }

  &__logs {
    margin-left: 2.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(12.5% - 0.75rem), 1fr));
    grid-gap: 0.75rem;
    flex: 1;
    width: 100%;
  }

  &--no-availability-system {
    .m-unit-report__data {
      opacity: 0.4;
    }
  }

  &--status-unavailable {
    .m-unit-report__data {
      background-color: $color-danger;
      border-color: darken($color-danger, 7%);
    }
  }

  &--status-available {
    .m-unit-report__data {
      background-color: $color-success;
      border-color: darken($color-success, 7%);
    }
  }
}
