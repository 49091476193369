/* Heading */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  font-weight: normal;
  line-height: 1.2;
}

/* Paragraph */

p {
  &:only-of-type {
    margin-bottom: 0;
  }
}

/* Hyperlink */
a {
  color: currentColor;

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
    outline: none;
    text-decoration: none;
  }
}

button {
  cursor: pointer;
}

/* Figure */

figure {
  margin: 0;
}

/* Image */

img {
  image-rendering: -webkit-optimize-contrast;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

/* Address */

address {
  font-style: normal;
}

/* Line */

hr {
  border: none;
  border-bottom: 1px solid lightgrey;
  height: 1px;
  margin: 1.5rem 0;
}

/* List */

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}
