/* Document */
*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  @include responsive-type;
}

html {
  --color-red: #fd0023;
  --color-green: #0fb923;

  background-image: url('~assets/images/background.svg');
  background-position: right 0 bottom 0;
  background-size: 75% auto;
  background-repeat: no-repeat;
  background-color: $color-background;

  color: $color-default-base;
  font-family: $font-family-default;
  font-weight: $font-weight-default;
  line-height: $line-height-default;
  min-height: 100%;
  text-rendering: optimizeLegibility;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: auto;

  @media #{$break-min-20} {
    background-size: 90% auto;
  }
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
}

/* HTML 5 */
figcaption,
figure,
main {
  display: block;
}

body {
  // overflow: hidden;
  position: relative;
  overflow-x: hidden;
}

#root {
  height: 100%;
}
