@import '~assets/scss/global';

.a-btn {
  border: none;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: $border-radius;

  &--theme-primary {
    background: $color-primary-base;

    &:hover {
      background: darken($color-primary-base, 7%);
    }
  }

  &--theme-primary {
    background: $color-background-alt;

    &:hover {
      background: lighten($color-background-alt, 7%);
    }
  }
}
