@import '~assets/scss/global';

.m-incident {
  border: 2px solid $border-color;
  border-radius: $border-radius;
  background-color: $color-background;
  height: 100%;
  padding: .5rem .375rem;
  transition: background-color .2s;

  &:hover {
    background-color: lighten($color-background, 1%);
  }

  &__upper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 .5rem;
  }

  &__title-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__title {
    margin: 0;
    font-size: 1.125rem;
    letter-spacing: 0px;
    font-weight: bold;
    display: inline-block;
  }

  &__subtitle {
    @include pseudo-space(before);
    display: inline-block;
    font-size: .925rem;
  }

  &__timer {
    @include pseudo-space(before);
    align-self: center;
    font-size: .95em;
    font-weight: bold;
  }


  &__list {
    height: 100%;

    align-items: normal;
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(320px, 0.35fr));
    justify-content: normal;
  }

  &__units {
    margin: .35rem 0 0;
    padding: 0;
    list-style: none;

    .t-incident-grid__item--span-2 & {
      display: grid;
      grid-gap: 1px 10px;

      @media #{$break-min-20} {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .t-incident-grid__item--span-3 & {
      display: grid;
      grid-gap: 1px 10px;

      @media #{$break-min-20} {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &__unit {
    &:not(:last-child ) {
      margin-bottom: 1px;
    }
  }

  &--latest {
    background-color: $color-background-alt;
    font-size: 1.25rem;
  }

  &--inactive {
    border-color: $border-color;
  }
}
