// @import './settings/setting.fonts';
@import './settings/setting.variables';

@import './tools/mixin.responsive-type';
@import './tools/mixin.pseudo';

@import './generics/generic.normalize';

@import './organisms/organism.app';
@import './organisms/organism.main';
@import './organisms/organism.footer';

@import './atoms/atom.icon';

@import './elements/element.form';
@import './elements/element.page';
@import './elements/element.table';
@import './elements/element.type';

@import './templates/template.layout';

.u-superscript {
  margin-left: 0.15em;
}

.m-form {
  &__item {
    & + & {
      margin-top: 0.75rem;
    }
  }

  &__label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    display: block;
  }

  &__field {
    border: 1px solid $border-color;
    background-color: $color-background;
    height: 2.75rem;
    line-height: 2.75rem;
    padding: 0 0.5rem;
  }

  &__actions {
    margin-top: 1rem;
  }
}
