@import '~assets/scss/global';

.m-empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4vw;

  &__logo {
    max-width: 60vw;
  }

  &__inner {
    text-align: center;
  }

  &__msg {
    display: block;
    margin-top: 2vh;
    font-size: 1.25rem;
    color: transparentize(white, .5);
    font-style: italic;
  }
}
