@import '~assets/scss/global';

.a-info-block {
  &__subject {
  }

  &__explanation {
    padding: 0.25rem;
    border: 2px solid $border-color;
    border-top-width: 0;

    ul,
    ol {
      margin: 0;
      padding-left: 1.25rem;
    }
  }
}
