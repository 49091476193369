@import '~assets/scss/global';

.t-incident-grid {
  grid-auto-flow: dense;
  grid-auto-rows: 0fr;
  grid-gap: 10px;
  padding: .5rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @media #{$break-min-20} {
    display: grid;
  }


  &__item {
    &--span-2 {
      grid-column: span 2;
    }

    &--span-3 {
      grid-column: span 2;

      @media #{$break-min-27} {
        grid-column: span 3;
      }
    }

    & + & {
      margin-top: 1.25rem;

      @media #{$break-min-20} {
        margin-top: 0;
      }
    }
  }

  &--phase-1 {
    grid-template-columns: repeat(2, minmax(calc(50%- 10px), 1fr));

    @media #{$break-min-27} {
      grid-template-columns: repeat(3, minmax(calc(33.33% - 10px), 1fr));
    }
  }
  
  &--phase-2 {
    grid-template-columns: repeat(2, minmax(calc(50% - 10px), 1fr));

    @media #{$break-min-27} {
      grid-template-columns: repeat(4, minmax(calc(25% - 10px), 1fr));
    }
  }
}