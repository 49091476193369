@import '~assets/scss/global';

.m-unit {
  border-radius: 2px;
  font-size: .75rem;
  padding: 0.35rem .5rem .1rem 0.5rem;

  transition: background-color .3s, opacity .3s;
  height: 100%;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  &__title {
    margin: 0 .875rem 0 0;
    font-size: 1.375rem;
    font-weight: bold;
    text-shadow: 1px 1px 1px #00000052;
  }

  &__context {
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

  &__context-block {
    border-radius: 2px;
    font-size: .925rem;
    font-weight: bold;
    padding: .25rem .5rem .175rem;
    margin-bottom: .25rem;
    line-height: 1.2;
    transition: background-color .3s;
    position: relative;
    text-align: right;

    span {
      position: relative;
      z-index: 2;
    }

    &:not(:last-child) {
      margin-right: .25rem;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
      background-color: transparentize($color: black, $amount: .85);
    }
  }

  &--hidden {
    display: none;
  }

  &--unknown {
    background-color: $color-unknown;

    .m-unit__context-block {
      background-color: darken($color-unknown, 7%);
    }
  }

  &--available {
    background-color: var(--color-green);

    & .m-unit__context-block {
      background-color: var(--color-green);

      &:before {
        background-color: transparentize($color: black, $amount: .65);
      }
    }
  }

  &--unavailable {
    background-color: var(--color-red);

    & .m-unit__context-block {
      background-color: var(--color-red);

      &:before {
        background-color: transparentize($color: black, $amount: .65);
      }
    }
  }

  &--no-availability-system {
    opacity: .5;
  }

  &--not-active-on-incident {
    opacity: 0.2;
  }

  .t-incident-grid__item--span-3 &,
  .t-incident-grid--phase-2 & {
    padding: 0.25rem 0.25rem 0 0.5rem;

    & .m-unit__title {
      font-size: 1.25rem;
    }
  }
}
