@import '~assets/scss/global';

.a-circle-text {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  justify-content: center;
  // color: $color-unknown;
  border: 5px solid $border-color;
  line-height: 1.2;
  text-align: center;
  padding-right: 0.25rem;
  letter-spacing: 1px;

  &__title {
    font-size: 3rem;
    font-weight: bold;
  }

  &__subtitle {
    font-size: 1.25rem;
  }
}
