@import '~assets/scss/global';

.o-statistics {
  padding: 2.5vmin 5vmin 2.5vmin 7.5vmin;
  overflow-y: scroll;

  &__group {
    border-bottom: 1px solid transparentize(white, 0.25);
    padding-bottom: 3rem;

    & + & {
      margin-top: 3rem;
    }
  }
}

.m-timeline {
  &__upper {
    margin-bottom: 2rem;
  }

  &__title {
    margin: 0;
  }

  &__content {
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    &:before {
      content: '';
      width: 3px;
      background-color: $border-color;
      display: block;
      position: absolute;
      top: -2rem;
      bottom: 0;
      left: calc(4.25rem - 1px);
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    margin-left: 4.25rem;

    & + & {
      margin-top: 5rem;
    }
  }

  &__meta {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-left: calc(-2.25rem + 1px);
    position: relative;
    height: 4.5rem;
    width: 4.5rem;
  }

  &__occurrence {
    color: transparentize(white, 0.1);
    margin: 0;
    font-size: 1.25rem;
    font-weight: bold;
    position: absolute;
    right: calc(100% + 1rem);
  }

  &__ball {
    width: 100%;
    height: 100%;
    background: $color-background;
    border: 3px solid $border-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .a-icon {
      width: 50%;
      height: 50%;
    }
  }

  &__detail-line {
    width: 7.5rem;
    background-color: $border-color;
    display: block;
    position: absolute;
    left: 4.5rem;
    top: calc(50% - 1px);
    height: 3px;
  }

  &__detail {
    padding-left: 7.5rem;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }
}

.m-report {
  &__subject {
    border-radius: $border-radius;
    border: 2px solid $border-color;
    background-color: $color-background;
    height: 4.5rem;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 1.25rem;

    &:before {
      content: '';
      width: 3px;
      background-color: $border-color;
      display: block;
      position: absolute;
      top: 100%;
      bottom: calc(-2.5rem - 2px);
      left: 5rem;
    }
  }

  &__units {
    margin-top: 2.5rem;
  }
}
