@import '~assets/scss/global';

.a-menu-card {
  background-color: $color-background;
  border: $border-radius solid $border-color;
  border-radius: 6px;
  padding: 12.5%;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  &:hover {
    background-color: lighten($color-background, 3%);
    text-decoration: underline;
  }

  &__icon {
    font-size: 3.75rem;
  }

  &__label {
    margin-top: 0.25rem;
    font-size: 1.5rem;
  }
}
