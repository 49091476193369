@import '~assets/scss/global';

.m-menu {
  &__btn {
    background-color: transparent;
    display: block;
    line-height: 0;

    padding: 0;
    margin: 0;
    border: none;

    color: white;
    opacity: 0.5;

    font-size: 1.5rem;

    transition: opacity 0.3s;

    outline: none;

    .a-icon {
      height: 1.5rem;
      width: 1.5rem;

      @media #{$break-min-25} {
        height: 2rem;
        width: 2rem;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  &__inner {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 0.5rem));
    grid-gap: 1rem;
    margin-bottom: 1.25rem;
  }

  &__block {
    padding: 1.5rem;

    & h5 {
      font-size: 1.75rem;
      font-weight: bold;
    }

    &--bottom {
      margin-top: auto;
    }
  }

  &__title {
  }
}
